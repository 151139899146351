<template>
  <div>
    <ManageBanner />
  </div>
</template>
<script>
import ManageBanner from "@/components/Banner/ManageBanner";
export default {
  components: {
    ManageBanner,
  },
  created() { this.year = JSON.parse(
        Decode.decode(localStorage.getItem("yearSelected"))
      );
  },
};
</script>